<template>
<section class="domain_content">
  <h1>
    Unduh Versi Terbaru WhatsApp GB untuk Pengguna iPhone
  </h1>

  <p>
    Sebenarnya, pengguna tidak dapat mengunduh <a href="/">WhatsApp GB</a> langsung di Apple Store, karena ini adalah aplikasi modifikasi yang berbasis pada WhatsApp resmi. Namun, Anda dapat mengunduh dan menginstal WhatsApp GB IOS X Android di ponsel Anda.
  </p>
  <p>
    Namun, penting untuk dicatat bahwa versi ini hanya meniru antarmuka iOS; ini tidak berarti Anda dapat menjalankan WhatsApp GB di iPhone.
  </p>
  <p>
    Sebenarnya, pengguna iPhone memiliki satu cara untuk menginstal alternatif WhatsApp GB. Namun, ini memerlukan jailbreak perangkat. Jailbreaking adalah proses yang kompleks dan memiliki risiko signifikan, seperti kerentanan keamanan dan membatalkan garansi Anda. Oleh karena itu, kami tidak merekomendasikan metode ini untuk menginstal alternatif WhatsApp GB di iOS.
  </p>

  <h2>
    Unduh WhatsApp GB untuk Android
  </h2>

  <div class="old-version-column">
    <div class="version-item">
      <div class="old-version-row">
        <img src="@/assets/shiny-logo.webp" class="head-img" alt="logo" />
        <div class="version-detail">
          <h4>WhatsApp GB 18.20</h4>
          <p><strong>Ukuran:</strong> 77MB</p>
          <p><strong>Terakhir Diperbarui:</strong> 2 Hari yang lalu</p>
          <p><strong>Unduhan:</strong> 1.000.000+</p>
          <p><strong>Sistem:</strong> Android</p>
        </div>
      </div>
      <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="unduh" class="download-img">
        <div class="download-name">
          Unduh
        </div>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import '@/css/default.scss';
import {
  mapGetters
} from 'vuex';

export default {
  ...{
    "metaInfo": {
      "title": "WhatsApp GB untuk iPhone | Unduh Versi Terbaru 2024",
      "meta": [{
        "name": "description",
        "content": "WhatsApp GB untuk iPhone 2024 telah memperbarui versi baru. Unduh versi terbaru untuk menikmati lebih banyak fitur kustom daripada WhatsApp biasa."
      }, {
        "name": "title",
        "content": "WhatsApp GB untuk iPhone | Unduh Versi Terbaru 2024"
      }, {
        "property": "og:title",
        "content": "WhatsApp GB untuk iPhone | Unduh Versi Terbaru 2024"
      }, {
        "property": "og:description",
        "content": "WhatsApp GB untuk iPhone 2024 telah memperbarui versi baru. Unduh versi terbaru untuk menikmati lebih banyak fitur kustom daripada WhatsApp biasa."
      }],
      "link": [{
        "rel": "canonical",
        "href": "https://waproapk.com/id/gbwhatsapp-for-iphone/"
      }]
    }
  },
  data() {
    return {
      from: null,
      filename: null,
      partners: null,
      updateTime: null,
      clicks: null,
      pageIntSuccess: null,
      apk: null,
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'host',
      "downloadicon"
    ])
  },
  created() {
    this.initParams();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    download(apk) {
      this.$global.download(apk);
    },

    initParams() {
      this.from = this.$global.GetQueryString('from');
      this.filename = this.$global.GetQueryString('filename');
    },
    fetchData() {
      if (this.from) {
        this.getAPK(true, this.from, this.filename);
      } else {
        this.getAPK();
        this.getOfficialApk();
      }
    },
    updateAPK() {
      this.$emit('update-apk', this.apk);
    },

    getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
      this.$server
        .getAPPInfo({
          promotionChannel: from,
          promotionName: filename
        })
        .then((res) => {
          if (res.code === 200) {
            this.partners = res.data.partners;
            this.updateTime = res.data.updateTime;
            this.clicks = res.data.clicks;
            this.pageIntSuccess = true;
            if (isFromQuery) {
              this.apk = res.data.apk;
              this.updateAPK();
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching APK:', err);
        });
    },
    getOfficialApk() {
      this.$server
        .getOfficialApk({
          domain: 'gbpro.download',
          appName: 'GB_gbpro.download'
        })
        .then((res) => {
          if (res.code === 200) {
            this.apk = res.data.apk;
            this.updateAPK();
            console.log('APK fetched successfully');
          }
        })
        .catch((err) => {
          console.error('Error fetching official APK:', err);
        });
    }
  }
};
</script>
